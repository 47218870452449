<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
                        </div>
                    </template>

                    <template v-slot:end>
                        <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
                    </template>
                </Toolbar>

                <DataTable ref="dt" :value="products" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Clientes"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              ">
              
                            <h5 class="m-0">Cheques</h5>
                            
                            <div class="d-flex flex p-fluid p-flex"> 
                                <!-- desde hasta -->
                                <div class="p-field p-grid">
                                    <label for="fecha_desde" class="p-col-fixed" style="width: 100px">Desde</label>
                                    <div class="p-col">
                                        <Calendar v-model="fecha_desde" dateFormat="dd/mm/yy" showIcon="true" />
                                    </div>
                                
                                </div>

                                
                                <div class="p-field p-grid ml-3">
                                    <label for="fecha_hasta" class="p-col-fixed" style="width: 100px">Hasta</label>
                                    <div class="p-col">
                                        <Calendar v-model="fecha_hasta" dateFormat="dd/mm/yy" showIcon="true" />
                                    </div>
                                </div>

                            </div>
                        <div> 

                            <div>
                                <Button label="Todos" class="p-button-rounded p-button-secondary mr-2" @click="filterAll" />
                                <Button label="Pendientes" class="p-button-rounded p-button-warning mr-2" @click="filterPendientes" />
                                <Button label="Cobrados" class="p-button-rounded p-button-success mr-2" @click="filterCobrados" />
                                <Button label="Anulados" class="p-button-rounded p-button-danger mr-2" @click="filterAnulados" />
                            </div>

                        </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column field="numero" header="N° Cheque" :sortable="true"
                        headerStyle="width:14%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">N° Cheque</span>
                            {{ slotProps.data.numero }}
                        </template>
                    </Column>
                    <Column field="banco" header="Banco" :sortable="true" headerStyle="width:14%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Banco</span>
                            {{ slotProps.data.banco }}
                        </template>
                    </Column>
                    <Column field="fecha_cobro" header="Fecha Cobro" :sortable="true"
                        headerStyle="width:14%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Fecha Cobro</span>
                            {{ slotProps.data.fecha_cobro }}
                        </template>
                    </Column>
                    <Column field="created_at" header="Ingreso" :sortable="true"
                        headerStyle="width:14%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Ingreso</span>
                            {{ slotProps.data.created_at }}
                        </template>
                    </Column>
                    <Column field="importe" header="Importe" :sortable="true" headerStyle="width:14%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Importe</span>
                            {{ formatCurrency(slotProps.data.importe) }}
                        </template>
                    </Column>
                    <Column field="estado" header="Estado" :sortable="true" headerStyle="width:14%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Estado</span>
                            <span :class="'product-badge status-' + slotProps.data.estado">
                                {{ parseEstado(slotProps.data) }}
                            </span>
                        </template>
                    </Column>
                    <Column headerStyle="min-width:10rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                                @click="editProduct(slotProps.data)" disabled/>
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                                @click="confirmDeleteProduct(slotProps.data)" disabled />
                        </template>
                    </Column>
                </DataTable>

                <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Cargar cheque" :modal="true"
                    class="p-fluid">
                    
          <Button label="Datos aleatorios" icon="pi pi-refresh" class="p-button-success" @click="fakerGenerate()" />
                    <div class="field">
                        <label for="name">N° de Cheque</label>
                        <InputText id="numero" v-model.trim="product.numero" required="true" autofocus
                            :class="{ 'p-invalid': submitted && !product.numero }" />
                        <small class="p-invalid" v-if="submitted && !product.numero">N° de cheque es requerido.</small>
                    </div>

                    <div class="formgrid grid">
                        <div class="field col">
                            <label for="banco">Banco</label>
                            <InputText id="banco" v-model="product.banco" />
                        </div>
                        <div class="field col">
                            <label for="fecha_cobro">Fecha cobro</label>
                            <Calendar id="fecha_cobro" v-model="product.fecha_cobro" dateFormat="dd/mm/yy" />
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field col">
                            <label for="importe">Importe</label>
                            <InputText id="importe" v-model="product.importe" />
                        </div>
                        <div class="field col">
                        </div>
                    </div>
                    <div class="field">
                        <label class="mb-3">Tipo</label>
                        <div class="formgrid grid">
                            <div class="field-radiobutton col-6">
                                <RadioButton id="category1" name="tipo" value="1" v-model="product.tipo" />
                                <label for="tipo1">Ingreso</label>
                            </div>
                            <div class="field-radiobutton col-6">
                                <RadioButton id="tipo2" name="tipo" value="2" v-model="product.tipo" />
                                <label for="tipo2">Egreso</label>
                            </div>
                        </div>
                    </div>
                    <div class="formgrid grid" v-if="product.tipo == 1">
                        <div class="field col">
                            <label for="id_cliente">Cliente</label>
                            <AutoComplete id="id_cliente" v-model="product.id_cliente" :suggestions="filteredClients"
                                @complete="searchClient($event)" field="name" :dropdown="true"
                                class="inputfield w-full" />

                        </div>
                        <div class="field col">
                            <label for="test">  *** </label>
                            ***
                        </div>
                    </div>
                    <div class="formgrid grid" v-if="product.tipo == 2">
                        <div class="field col">
                            <label for="id_proveedor">Proveedor</label>
                            <AutoComplete id="id_proveedor" v-model="product.id_proveedor" :suggestions="filteredProviders"
                                @complete="searchProvider($event)" field="name" :dropdown="true"
                                class="inputfield w-full" />

                        </div>
                        <div class="field col">
                            <label for="test">  *** </label>
                            ***
                        </div>
                    </div>

                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
                        <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" header="Confirmación"
                    :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span v-if="product">Eliminaras a <b>{{ product.name }}</b>?</span>
                    </div>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" class="p-button-text"
                            @click="deleteProductDialog = false" />
                        <Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteProduct" />
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="Confirm"
                    :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span v-if="product">Seguro?</span>
                    </div>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" class="p-button-text"
                            @click="deleteProductsDialog = false" />
                        <Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script>

import { FilterMatchMode } from "primevue/api";
import CrudService from "../services/crud.service";

import faker from "../../public/extras/faker.min.js";

class Cheque {
	constructor() {
	// 	this.name = faker.name.firstName();
    
	// 	this.phone  = faker.phone.phoneNumber();
	// 	this.email = faker.internet.email();
    // this.address = faker.address.streetAddress();
    // this.location = faker.address.city();
    // this.postalcode = faker.random.number({min: 1, max: 4});
    // this.province = faker.address.state();
    // this.web = faker.internet.url();
    this.numero = faker.random.number({min: 1, max: 800}); 
    this.tipo = faker.random.number({min: 1, max: 2}); 
    this.importe = faker.random.number({min: 1, max: 1000});
    this.banco = faker.random.arrayElement(['Banco de la Nación', 'Banco de Galicia S.A.', 'Banco de la Provincia', 'Banco Macro', 
           'Banco Santander Río', 'Banco Patagonia', 'Banco Ciudad', 'Banco Credicoop', 'Banco Hipotecario', 'Banco Itaú', 'Banco Supervielle'
        ]);

    this.fecha_cobro = faker.date.future();
 

	}
}

export default {
    data() {
        return {
            routeName: null,
            products: null,
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            product: {

            },
            selectedProducts: null,
            filters: {},
            submitted: false,
            validationErrors: [],
            proveedores: null,
            clients: null,
            filteredClients: [],
            filteredProviders: [],
            fecha_desde: null,
            fecha_hasta: null,
        };
    },

    created() {
        // get route name
        this.routeName = this.$route.name;

        CrudService.getCRUD("api/proveedor").then(
            (data) => (this.proveedores = data)
        );

        CrudService.getCRUD("api/clients").then(
            (data) => (this.clients = data)
        );

        this.initFilters();
    },
    mounted() {
        CrudService.getCRUD('api/cheques').then(
            (data) => (this.products = data)
        );
    },
    methods: {
        formatCurrency(value) {
            return   value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        },
        parseEstado(data) {
            if (data.estado == 0 && data.id_cliente) {
                return "Recibido";
            }

            if(data.estado == 0 && data.id_proveedor) {
                return 'Emitido';
            }

            
        },
        fakerGenerate(){

        var cheque = new Cheque();
        console.log(cheque)

        if(cheque.tipo == 1){
            // random client
            cheque.id_cliente = faker.random.arrayElement(this.clients);
        }else{
            // random provider
            cheque.id_proveedor = faker.random.arrayElement(this.proveedores);
        }
        this.product = JSON.parse(JSON.stringify(cheque))
        },
        searchClient(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredClients = [...this.clients];
                } else {
                    this.filteredClients = this.clients.filter((prod) => {
                        return prod.name
                            .toLowerCase()
                            .startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        searchProvider(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.filteredProviders = [...this.proveedores];
                } else {
                    this.filteredProviders = this.proveedores.filter((prod) => {
                        return prod.name
                            .toLowerCase()
                            .startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
 
        openNew() {
            this.product = {
                moneda: '1'
            };
            this.submitted = false;
            this.productDialog = true;
        },
        hideDialog() {
            this.productDialog = false;
            this.submitted = false;
        },
        displayErrors(errors) {

            for (let key in errors) {

                this.validationErrors.push({ "field": key, "message": errors[key] });

                let error = errors[key];
                this.$toast.add({
                    severity: "error",
                    summary: "Error!",
                    detail: error[0],
                    life: 3000,
                });
            }
        },
        saveProduct() {
            this.submitted = true;

                    CrudService.createCRUD('api', 'cheques', this.product).then(
                        (data) => {

                            this.validationErrors = [];
                            if (data.status == 400) {

                                this.displayErrors(data.data);

                            } else {
                                this.products.push(data.data);
                                this.$toast.add({
                                    severity: "success",
                                    summary: "Éxito!",
                                    detail: "Creado correctamente.",
                                    life: 3000,
                                });
                                this.productDialog = false;
                                this.product = {};
                            }
                        }
                    );
                
            
        },
        editProduct(product) {
            this.product = { ...product };
            this.productDialog = true;
        },
        confirmDeleteProduct(product) {
            this.product = product;
            this.deleteProductDialog = true;
        },
        deleteProduct() {

            CrudService.deleteCRUD('api', 'cheques', this.product.id).then(
                (data) => {

                    console.log(data.id)

                    this.products = this.products.filter(

                        (val) => {
                            console.log(val)
                            val.id !== data.id
                        }
                    );

                    console.log(this.products)

                    this.$toast.add({
                        severity: "success",
                        summary: "Éxito!",
                        detail: "Eliminado correctamente.",
                        life: 3000,
                    });
                }
            );

            this.deleteProductDialog = false;
            this.product = {};
        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        createId() {
            let id = "";
            var chars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 5; i++) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        confirmDeleteSelected() {
            this.deleteProductsDialog = true;
        },
        deleteSelectedProducts() {
            this.products = this.products.filter(
                (val) => !this.selectedProducts.includes(val)
            );
            this.deleteProductsDialog = false;
            this.selectedProducts = null;
            this.$toast.add({
                severity: "success",
                summary: "Éxito!",
                detail: "Eliminado correctamente.",
                life: 3000,
            });
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
